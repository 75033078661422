<template>
  <div>
    <HomePublishPanel ref="target" title="简单便捷的交易流程" subTitle="">
      <template v-slot:default>
        <div class="box">
          <Row
            style="
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <Col span="2">
              <img src="../../../../static/imgs/publish/p1.png" />
            </Col>
            <Col span="2" style="margin-bottom: 30px; margin-left: 10px">
              <img src="../../../../static/imgs/publish/link.png" />
            </Col>
            <Col span="2">
              <img src="../../../../static/imgs/publish/p2.png" />
            </Col>
            <Col span="2" style="margin-bottom: 30px">
              <img src="../../../../static/imgs/publish/link.png" />
            </Col>

            <Col span="2">
              <img src="../../../../static/imgs/publish/p3.png" />
            </Col>
            <Col span="2" style="margin-bottom: 30px">
              <img src="../../../../static/imgs/publish/link.png" />
            </Col>
            <Col span="2">
              <img src="../../../../static/imgs/publish/p4.png" />
            </Col>
            <Col span="2" style="margin-bottom: 30px">
              <img src="../../../../static/imgs/publish/link.png" />
            </Col>
            <Col span="2">
              <img src="../../../../static/imgs/publish/p5.png" />
            </Col>
            <Col span="2" style="margin-bottom: 30px">
              <img src="../../../../static/imgs/publish/link.png" />
            </Col>
            <Col span="2">
              <img src="../../../../static/imgs/publish/p6.png" />
            </Col>
          </Row>
        </div>
        <div>
          <!-- style="width: 100%; height: 50px" style="margin-bottom: 30px" -->
          <Row style="height: 100px">
            <Col span="8"></Col>
            <Col span="8">
              <Button class="btn" style="margin-left: 35%" @click="publish">
                我要发布
              </Button>
            </Col>
            <Col span="8"></Col>
          </Row>
        </div>
      </template>
    </HomePublishPanel>
    <Modal
      v-model="showDialog"
      title="我要发布"
      :footer-hide="true"
      :width="400"
      :mask="true"
    >
      <div class="radio-group">
        <RadioGroup v-model="publishType">
          <Radio :label="1">发布采购</Radio>
          <Radio :label="2">发布商品</Radio>
        </RadioGroup>
      </div>
      <div class="footer">
        <Button @click="showDialog = false">取消</Button>
        <Button class="commit-btn" @click="okClick">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import HomePublishPanel from '@/views/home/components/HomePublishPanel'
// import useLazyData from '@/hooks/useLazyData'
// import { getBrands } from '@/api/home'
// import useBrandToggle from '@/hooks/home/useBrandToggle'
// import { nextTick } from 'vue'

export default {
  name: 'HomeBrand',
  components: { HomePublishPanel },
  data () {
    return {
      showDialog: false,
      publishType: 1,
      login: '',
    }
  },
  setup () {
    // const { target, result: brands } = useLazyData(getBrands)
    // return {
    //   brands,
    //   target,
    //   ...useBrandToggle(brands),
    // }
  },
  created () {
    this.login = this.$store.state.login.userInfo
    console.log(this.login)
  },
  methods: {
    okClick () {
      if (!this.login) {
        this.$router.push('/login')
        return
      }
      this.showDialog = false
      if (this.publishType === 1) {
        this.$router.push('/publish')
      } else if (this.publishType === 2) {
        this.$router.push('/user/merchandise/publish')
      }
    },
    publish () {
			if (this.login.status < 4) {
        this.$Notice.info({
          title: '账号未认证',
          desc: '请先去个人中心进行商家认证',
        })
				return
      }
      this.showDialog = true
    },
  },
}
</script>

<style scoped lang="less">
.home-panel {
  background: #f5f5f5;
}
.iconfont {
  width: 20px;
  height: 20px;
  background: #ccc;
  color: #fff;
  display: inline-block;
  text-align: center;
  margin-left: 5px;
  background: @xtxColor;
  &::before {
    font-size: 12px;
    position: relative;
    top: -2px;
  }
  &.disabled {
    background: #ccc;
    cursor: not-allowed;
  }
}
.box {
  display: flex;
  width: 100%;
  height: 200px;
  overflow: hidden;
  // padding-bottom: 40px;
}

.btn {
  // display: block;
  width: 144px;
  background: #d12b23;
  height: 37px;
  font-weight: 400;
  font-size: 14px;
  // color: #fff;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: 1px solid #cfcdcd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  .ivu-btn {
    margin-right: 10px;
  }
  .commit-btn {
    width: 60px;
    padding: 0;
  }
}
.radio-group {
  padding-left: 30px;
}
</style>
