<template>
  <div class="home-category">
    <div class="container">全部商品分类</div>
    <div ref="navSide">
      <!--  @mouseenter="showDetail(item)"
        @mouseleave="hideDetail()" -->
      <div
        v-for="item in categoryTreeList"
        :key="item.deposit.categoryId"
        class="container-list"
      >
        <Row
          style="
            height: 50%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <Col span="4">
            <img
              :src="item.parentCategory.showIcon"
              alt=""
              style="margin-left: 10px"
            />
          </Col>
          <Col span="16">
            <span
              style="
                color: #333333;
                font-size: 16px;
                font-weight: 550;
                margin-left: 5px;
              "
            >
              {{ item.deposit.categoryName }}
            </span>
          </Col>
          <Col span="4"><i class="iconfont icon-angle-right"></i></Col>
        </Row>
        <Row style="height: 50%; width: 100%">
          <Col span="4"></Col>
          <Col span="16">
            <router-link
              v-for="(items, subIndex) in item.subCategoryList.slice(0, 3)"
              :key="subIndex"
              :to="`/category/sub/${items.id}`"
            >
              <span class="detail-item" style="color: #666666">
                {{ items.categoryName }}
              </span>
            </router-link>
          </Col>
          <Col span="4"></Col>
        </Row>
        <div class="line"></div>
      </div>
      <div
        class="container-list"
        @mouseenter="showDetail()"
        @mouseleave="hideDetail()"
      >
        <Row
          style="
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <Col span="4"></Col>
          <Col span="16">
            <span
              style="
                color: #333333;
                font-size: 16px;
                font-weight: 550;
                margin-left: 5px;
              "
            >
              更多
            </span>
          </Col>
          <Col span="4"><i class="iconfont icon-angle-right"></i></Col>
        </Row>
      </div>
    </div>
    <!-- 浮窗 -->
    <transition name="fade">
      <div
        v-show="panel2"
        ref="itemPanel2"
        class="detail-item-panel panel-2"
        :duration="{ enter: 100, leave: 100 }"
        @mouseenter="showMoreDetail()"
        @mouseleave="hideDetail()"
      >
        <div v-if="showFloat">
          <ul>
            <li class="detail-item-row">
              <span class="detail-item-title">
                {{ showList.deposit.categoryName }}
                <span class="glyphicon glyphicon-menu-right"></span>
              </span>
              <router-link
                v-for="item in showList.subCategoryList"
                :key="item.id"
                :to="`/category/sub/${item.id}`"
              >
                <!-- :path="`/category/sub/${item.subCategoryId}` -->
                <span class="detail-item">{{ item.categoryName }}</span>
              </router-link>
            </li>
          </ul>
        </div>
        <div v-else>
          <ul>
            <li
              v-for="(items, index) in categoryTreeListAll"
              :key="index"
              class="detail-item-row"
            >
              <span class="detail-item-title">
                {{ items.deposit.categoryName }}
                <span class="glyphicon glyphicon-menu-right"></span>
              </span>
              <router-link
                v-for="(item, subIndex) in items.subCategoryList"
                :key="subIndex"
                :to="`/category/sub/${item.id}`"
              >
                <span class="detail-item">{{ item.categoryName }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// // import useMenuList from '@/hooks/home/useMenuList'
// import { ref } from 'vue'

export default {
  name: 'HomeCategory',
  data () {
    return {
      panel2: false,
      categoryTreeList: {}, // 前四条
      categoryTreeListAll: {}, // 所有
      showFloat: false, // true: 显示某一类商品浮窗; false:显示所有
      showList: {},
    }
  },
  created () {
    this.init()
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.itemPanel2.style.left =
        this.$refs.navSide.offsetLeft + this.$refs.navSide.offsetWidth + 'px'
      this.$refs.itemPanel2.style.top = this.$refs.navSide.offsetTop - 45 + 'px'
    })
  },
  updated () {
    this.$nextTick(() => {
      this.$refs.itemPanel2.style.left =
        this.$refs.navSide.offsetLeft + this.$refs.navSide.offsetWidth + 'px'
      this.$refs.itemPanel2.style.top = this.$refs.navSide.offsetTop - 45 + 'px'
    })
  },
  methods: {
    init () {
      this.$store
        .dispatch('CategoryTree')
        .then((response) => {

          this.categoryTreeList = response.result.slice(0, 4)
          this.categoryTreeListAll = response.result
        })
        .catch(() => {})
    },
    showDetail (item) {
      this.panel2 = true
      if (item) {
        this.showList = item
        this.showFloat = true
      } else {
        this.showFloat = false
        this.showList = this.categoryTreeListAll
      }
    },
    showMoreDetail () {
      this.panel2 = true
    },
    hideDetail () {
      this.panel2 = false
    },
  },
  setup () {
    // const menuList = useMenuList()
    // // 定义当前移入的左菜单栏
    // const current = ref(null)
    // return { menuList, current }
  },
}
</script>
<style scoped lang="less">
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d12b23;
  margin-bottom: 5px;
  border: 1px solid #eee;
  color: #eee;
  border-radius: 4px;
  width: 250px;
  height: 40px;
}

.line {
  height: 1px;
  background: #dcdfe6;
}

.container-list {
  height: 80px;
  width: 100%;
  margin-top: 5px;
}

.home-category {
  width: 250px;
  height: 455px;
  // background: rgba(0, 0, 0, 0.8);
  background: #fff;
  position: relative;
  border: 1px solid #eee;
  z-index: 99;
}

/*显示商品详细信息*/
.detail-item-panel {
  // width: 900px;
  min-width: 600px;
  height: 455px;
  // background: rgba(255, 255, 255, 1);
  background-color: #fff;
  border: 1px solid #d12b23;
  /* box-shadow:水平位置 垂直位置 模糊距离 阴影尺寸（影子大小） 阴影颜色  内/外阴影； */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 168px;
  left: 389px;
  z-index: 999;
}

.nav-detail-item {
  margin-left: 26px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  color: #eee;
}

.nav-detail-item span {
  padding: 6px;
  padding-left: 12px;
  margin-left: 15px;
  font-size: 12px;
  background-color: #6e6568;
}

.nav-detail-item span:hover {
  margin-left: 15px;
  background-color: #f44336;
}

.detail-item-panel ul {
  list-style: none;
}

.detail-item-panel li {
  line-height: 38px;
  margin-left: 40px;
}

.detail-item-title {
  padding-right: 6px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  color: #555555;
}

.detail-item-title:hover {
  color: #d9534f;
}

.detail-item-row a {
  color: #555555;
}

.detail-item {
  font-size: 14px;
  padding-left: 12px;
  padding-right: 8px;
  cursor: pointer;
  border-left: 1px solid #ccc;
}

.detail-item:hover {
  color: #d9534f;
}
</style>
