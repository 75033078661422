<template>
  <div class="home-info">
    <Row style="padding: 20px; height: 50%; width: 100%">
      <!-- <Col span="12">col-12</Col> -->
      <Row style="height: 60%; width: 100%">
        <Col span="24" class="container">
          <img
            src="../../../../static/icons/header.png"
            alt=""
            style="height: 80px; width: 80px"
          />
        </Col>
      </Row>
      <Row style="height: 20%; width: 100%">
        <Col span="24" class="container">
          <div v-if="login.token">
            <span style="font-weight: 400; font-size: 20px">
              {{ login.name }}
            </span>
          </div>
          <div v-else>
            <span style="font-weight: 400; font-size: 20px">Hi,你好</span>
          </div>
        </Col>
      </Row>
      <Row style="height: 20%; width: 100%">
        <Col span="12" class="container">
          <div v-if="login.token" @click="toMemberHome()">
            <Row>
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="../../../../static/icons/user.png"
                  alt=""
                  style="height: 20px; width: 20px"
                />
              </div>
            </Row>

            <Row>
              <a style="font-weight: 500; font-size: 14px">用户中心</a>
            </Row>
          </div>
          <div v-else>
            <Button type="error" class="btn-login" @click="toLogin()">
              登录
            </Button>
          </div>
        </Col>
        <Col span="12" class="container">
          <div v-if="login.token" @click="logout()">
            <Row>
              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <img
                  src="../../../../static/icons/loginOut.png"
                  alt=""
                  style="height: 20px; width: 20px"
                />
              </div>
            </Row>
            <Row>
              <a style="font-weight: 500; font-size: 14px">退出登录</a>
            </Row>
          </div>
          <div v-else>
            <Button class="btn" @click="toRegister()">注册</Button>
          </div>
        </Col>
      </Row>
    </Row>
    <Row>
      <Col span="24">
        <Card style="width: 100%; height: 100%">
          <template #title>
            <img src="../../../../static/icons/sign.png" alt="" />
            <span style="font-weight: 600; font-size: 16px; margin-left: 8px">
              平台公告
            </span>
          </template>
          <template #extra>
            <a href="#" @click.prevent="changeLimit">
              <Icon type="ios-loop-strong"></Icon>
              <!-- 更多 -->
              <MyMore to="/message" />
              <!-- <i class="iconfont icon-angle-right"></i> -->
            </a>
          </template>
          <p
            v-for="item in noticeList"
            :key="item.id"
            class="rate-demo"
            @click="toDetail(item.id)"
          >
            <a target="_blank" style="font-size: 14px; font-weight: 400">
              {{ item.title }}
            </a>
            <span style="color: #999999; font-size: 13px; font-weight: 400">
              {{ item.createDate }}
            </span>
          </p>
        </Card>
      </Col>
    </Row>
    <!-- </Card> -->
  </div>
</template>
<script>
// import useBanners from '@/hooks/useBanners'
import { useStore } from 'vuex'
import { timeToStampToTime } from '@/utils/dateFormat'
import MyMore from '../../../components/library/MyMore.vue'
import { ref } from 'vue'
export default {
  name: 'HomeInfo',
  data () {
    return {
      noticeList: [],
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const postData = ref({
        page: 1, // 当前页
        pageRows: 5, // 数据数量
      })
      this.$store
        .dispatch('NoticeList', postData.value)
        .then((response) => {
          // console.log('NoticeList11: ', response.result.rows)
          this.noticeList = response.result.rows.map((item) => ({
            title: item.title,
            createDate: timeToStampToTime(item.createDate),
						id: item.id
          }))
        })
        .catch(() => {})
    },
    toLogin () {
      this.$router.push('/login').then(() => {})
    },
    toRegister () {
      this.$router.push('/register').then(() => {})
    },
    toMemberHome () {
      if (this.login.status < 4) {
        // 未认证 的普通用户
        this.$router.push('/user/nominalominalhome').then(() => {})
      } else {
        // 已认证的 用户
        this.$router.push('/user/home').then(() => {})
      }
    },
    logout () {
      this.$store
        .dispatch('OutLogin')
        .then((response) => {
          if (response.returnCode === '1') {
            this.$store.commit('login/userInfo', {}) // 清空用户信息
            this.$router.push('/login').then(() => {
              // instance.$message({ type: 'success', text: '退出成功' })
            })
          }
        })
        .catch(() => {})
    },
		 toDetail (id) {
			this.$router.push({ path: '/message/detail', query: { messageId: id }})
    },
  },
  setup () {
    // 使用vuex
    const store = useStore()
    // 使用router
    // const router = useRouter()
    // 获取用户信息
    const login = store.state.login.userInfo
    // console.log('login:', login)
    // const logout = (instance) => {
    //   store.commit('user/setUser', {}) // 清空用户信息
    //   store.commit('cart/setCart', []) // 清空购物车
    //   router.push('/login').then(() => {
    //     instance.$message({ type: 'success', text: '退出成功' })
    //   })
    // }

    return {
      login,
      //   logout,
    }
  },
  components: { MyMore },
}
</script>
<style scoped lang="less">
.home-info {
  // width: 1240px;
  width: 300px;
  height: 455px;
  position: absolute;
  left: 940px;
  top: 0;
  z-index: 98;
  background: #fff;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  display: block;
  width: 94px;
  height: 40px;
  // color: #fff;
  text-align: center;
  line-height: 40px;
  border: 1px solid #cfcdcd;
}
.btn-login {
  display: block;
  width: 94px;
  height: 40px;
  background: #d12b23;
  text-align: center;
  line-height: 40px;
  border: 1px solid #cfcdcd;
}
.rate-demo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 550;
  margin-bottom: 10px;
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
    white-space: nowrap; //文本不换行，这样超出一行的部分被截取，显示...
  }
}
</style>
