<template>
  <HomePanel ref="target" title="最新采购" sub-title="">
    <template v-slot:right>
      <MyMore :path="'/publish/needslist'" />
    </template>
    <Transition name="fade">
      <Table :columns="columns" class="container" :data="dataList">
        <template #createDate="{ row }">
          <span class="table-text">
            {{ row.createDate }}
          </span>
        </template>
        <template #productName="{ row }">
          <span class="table-text">
            {{ row.productName }}
          </span>
        </template>
        <template #formate="{ row }">
          <span class="table-text">
            {{ row.formate }}
          </span>
        </template>
        <template #price="{ row }">
          <Numeral :value="row.price" format="0,0.00" class="table-text" style="color: #de6a64">
            <template #prefix>
              <strong>¥</strong>
            </template>
          </Numeral>
          <span class="table-text" style="color: #de6a64">&nbsp;/{{ row.unitName }}</span>
        </template>
        <template #status="{ row }">
          <span>
            <span class="table-text">{{ statusList[row.status] }}</span>
            <span style="color: #de6a64">( {{ row.qoCount }} )</span>
          </span>
        </template>
        <template #count="{ row }">
          <span class="table-text">
            {{ row.count }}
          </span>
        </template>
        <template #shortName="{ row }">
          <span class="table-text">
            {{ row.shortName }}
          </span>
        </template>
        <template #action="{ row }">
          <!-- <a class="table-text" style="color: #d12b23" @click="toDetail(row)">
            查看详情
          </a> -->
          <Button
            type="text"
            size="small"
            style="margin-right: 5px"
            class="action-btn"
            @click="toDetail(row)"
          >
            查看详情
          </Button>
          <!-- <a
            class="table-text"
            style="color: #d12b23"
            @click="showQuotedPrice(row)"
          >
            报价
          </a> -->
          <Button
          size="small"
          class="commit-btn"
          @click="showQuotedPrice(row)"
        >报价</Button>
        </template>
      </Table>
    </Transition>
  </HomePanel>
  <QueteModal
    v-if="showQuote"
    :id="id"
    :showModal="showQuote"
    @cancel="showQuote = false"
  ></QueteModal>
</template>

<script>
import HomePanel from '@/views/home/components/HomePanel'
// import useLazyData from '@/hooks/useLazyData'
import QueteModal from '../../publish/components/QueteModal'
// import { getHomeHot } from '@/api/home'
export default {
  name: 'HomeProcurement',
  components: { HomePanel, QueteModal },
  data () {
    return {
      showQuote: false,
      id: null,
      statusList: {
        // 所有订单状态
        1: '待报价',
        2: '待确认',
        3: '已取消',
        4: '撮合成功',
      },
      columns: [
        {
          key: 'createDate',
          title: '发布时间',
          slot: 'createDate',
          width: 200,
        },
        {
          key: 'productName',
          title: '商品名称',
          slot: 'productName',
        },
        {
          title: '规格/型号',
          key: 'formate',
          slot: 'formate',
        },
        {
          title: '采购价',
          key: 'price',
          slot: 'price',
          width: 150,
        },
        {
          title: '状态',
          key: 'status',
          width: 120,
          slot: 'status',
        },
        {
          title: '采购量',
          key: 'count',
          slot: 'count',
          width: 120,
        },
        {
          title: '发布企业',
          key: 'shortName',
          slot: 'shortName',
          width: 200,
        },
        {
          title: '操作',
          slot: 'action',
          width: 180,
        },
      ],
      dataList: [],
    }
  },
  created () {
    this.getPreOrderGoodsList()
  },
  methods: {
    toDetail (row) {
      this.$router.push({ name: 'needslist_detail', params: { id: row.id }})
    },
    showQuotedPrice (row) {
      this.showQuote = true
      this.id = row.id
    },
    getPreOrderGoodsList () {
      const params = {
        page: 1,
        rows: 5,
        productName: '',
        formate: '',
        companyName: '',
        categoryId: '',
        beginDate: '',
        sidx: 'create_date',
        sord: 'desc',
      }
      this.$store
        .dispatch('publish/GetPreOrderGoodsList', params)
        .then((res) => {
          if (res.returnCode === '1') {
            this.dataList = res.result.rows
          }
        })
    },
  },
  setup () {
    // const homeHot = useHomeHot();
    // const { target, result: homeHot } = useLazyData(getHomeHot)
    // console.log('homeHot:', homeHot)
    // return { homeHot, target }
  },
}
</script>
<style scoped lang="less">
.container {
  height: 426px;
  .table-text {
    font-size: 14px;
    // font-weight: 600;
    color: #333333;
  }
}
/deep/ th .ivu-table-cell {
  font-size: 14px;
  font-weight: 550;
  color: #333333;
}

.action-btn {
  color: #d12b23;
  background: none;
  box-shadow: none;
}
.commit-btn {
  width: 50px;
  padding: 0;
  box-shadow: none;
}
.commit-btn:hover {
  border: none;
  opacity: 1;
}
</style>
