<template>
  <AppLayout>
    <div class="container">
      <!-- 左侧分类栏 -->
      <HomeCategory />
      <!-- 轮播图 -->
      <HomeBanner />
      <HomeUserInfo />
    </div>
    <!-- 我要发布 -->
    <HomePublish />
    <!-- 交易专区 -->
    <HomeTrading />
    <!--最新供应 -->
    <HomeSupply />
    <!--最新采购 -->
    <HomeProcurement />

  </AppLayout>
</template>
<script>
import AppLayout from '@/components/AppLayout'
import HomeCategory from '@/views/home/components/HomeCategory'
import HomeBanner from '@/views/home/components/HomeBanner'
import HomePublish from '@/views/home/components/HomePublish'
import HomeUserInfo from '@/views/home/components/HomeUserInfo'
import HomeSupply from '@/views/home/components/HomeSupply'
import HomeProcurement from '@/views/home/components/HomeProcurement'
import HomeTrading from '@/views/home/components/HomeTrading'

export default {
  name: 'HomePage',
  components: {

    HomeTrading,
    HomeProcurement,
    HomeSupply,
    HomePublish,
    HomeBanner,
    HomeUserInfo,
    HomeCategory,
    AppLayout,
  },
}
</script>
<style lang="less" scoped>
.container {
  margin-top: 15px;
}
</style>
