<template>
  <div class="home-panel">
    <div class="container">
      <div class="head">
        <h3>
          {{ title }}<small>{{ subTitle }}</small>
        </h3>
        <!-- <slot name="center"></slot> -->
        <slot name="right"></slot>
      </div>
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePanel',
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="less">
.home-panel {
  background-color: #fff;

  .head {
    padding: 25px 0;
    display: flex;
    align-items: flex-end;
    h3 {
      flex: 1;
      font-size: 22px;
      font-weight: normal;
			color: #333333;
      margin-left: 6px;
      height: 35px;
      line-height: 35px;

      small {
        font-size: 12px;
        color: #999999;
        margin-left: 20px;

      }
    }
  }
}
</style>
