<template>
  <HomePanel ref="target" title="交易专区" sub-title="">
    <template v-slot:right>
      <MyMore :path="`/trading-area`" />
    </template>
    <Transition name="fade">
      <Tabs :animated="false" class="container">
        <TabPane label="采购">
          <Table :columns="columns" :data="dataProcurement">
            <template #productName="{ row }">
              <span class="table-text">
                {{ row.productName }}
              </span>
            </template>
            <template #formate="{ row }">
              <span class="table-text">
                {{ row.formate }}
              </span>
            </template>
            <template #material="{ row }">
              <span class="table-text">
                {{ row.material }}
              </span>
            </template>
            <template #avgPrice="{ row }">
              <Numeral
                :value="row.avgPrice"
                format="0,0.00"
                class="table-text"
                style="color: #de6a64"
              >
                <template #prefix>
                  <strong>¥</strong>
                </template>
              </Numeral>
            </template>
            <template #unitName="{ row }">
              <span class="table-text">
                {{ row.unitName }}
              </span>
            </template>
            <template #sumInventory="{ row }">
              <span class="table-text">
                {{ row.sumInventory }}
              </span>
            </template>
          </Table>
        </TabPane>
        <TabPane label="供应">
          <Table :columns="columns" :data="dataSupply">
            <template #productName="{ row }">
              <span class="table-text">
                {{ row.productName }}
              </span>
            </template>
            <template #formate="{ row }">
              <span class="table-text">
                {{ row.formate }}
              </span>
            </template>
            <template #material="{ row }">
              <span class="table-text">
                {{ row.material }}
              </span>
            </template>
            <template #avgPrice="{ row }">
              <Numeral
                :value="row.avgPrice"
                format="0,0.00"
                class="table-text"
                style="color: #de6a64"
              >
                <template #prefix>
                  <strong>¥</strong>
                </template>
              </Numeral>
            </template>
            <template #unitName="{ row }">
              <span class="table-text">
                {{ row.unitName }}
              </span>
            </template>
            <template #sumInventory="{ row }">
              <span class="table-text">
                {{ row.sumInventory }}
              </span>
            </template>
          </Table>
        </TabPane>
      </Tabs>
    </Transition>
  </HomePanel>
</template>

<script>
import HomePanel from '@/views/home/components/HomePanel'
import { ref } from 'vue'
export default {
  name: 'HomeProcurement',
  components: { HomePanel },
  data () {
    return {
      columns: [
        {
          title: '商品名称',
          key: 'productName',
          slot: 'productName',
        },
        {
          title: '规格',
          key: 'formate',
          slot: 'formate',
        },
        {
          title: '材质',
          key: 'material',
          slot: 'material',
        },
        {
          title: '价格',
          key: 'avgPrice',
          slot: 'avgPrice',
          // className: 'avgPrice-table-info-column',
        },
        {
          title: '单位',
          key: 'unitName',
          slot: 'unitName',
        },

        {
          title: '当前总库存',
          key: 'sumInventory',
          slot: 'sumInventory',
        },
      ],
      // 求购数据
      dataProcurement: [],
      // 供应数据
      dataSupply: [],
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      // 采购统计
      const postData = ref({
        productName: '',
        formate: '',
        material: '',
      })
      this.$store
        .dispatch('PurchaseStatistics', postData.value)
        .then((response) => {
          this.dataProcurement = response.result.slice(0, 5)
        })
        .catch(() => {})
      // 供应统计
      this.$store
        .dispatch('SupplyStatistics', postData.value)
        .then((response) => {
          // console.log('供应统计', response)
          this.dataSupply = response.result.slice(0, 5)
        })
        .catch(() => {})
    },
  },
  setup () {},
}
</script>
<style scoped lang="less">
.container {
  // height: 426px;
	min-height: 350px;
  .table-text {
    font-size: 14px;
		color: #333333;
    // font-weight: 600;
  }
}
/deep/ .ivu-tabs-nav{
font-size: 16px
}
/deep/ .ivu-tabs-nav .ivu-tabs-tab-active {
  color: #d12b23;
}
/deep/ .ivu-tabs-nav .ivu-tabs-tab-active {
  color: #d12b23;
}
/deep/ .ivu-tabs-ink-bar {
  background-color: #d12b23;
}
/deep/ .ivu-table td.avgPrice-table-info-column {
  // background-color: #2db7f5;
  color: #d12b23;
  font-size: 14px;
  font-weight: 500;
}
/deep/ th .ivu-table-cell {
  font-size: 14px;
  font-weight: 550;
	color: #333333;
}
</style>
