<template>
  <HomePanel ref="target" title="最新供应" sub-title="">
    <template v-slot:right>
      <MyMore :path="`/category/sub/${item.subCategoryId}`" />
			<!-- <MyMore :path="`/category/sub`" /> -->
    </template>
    <Transition name="fade">
      <ul v-if="goods" class="goods-list">
        <li v-for="item in goods.rows" :key="item.id">
          <RouterLink :to="`/goods/${item.id}`">
            <img :src="item.img" />
            <p class="name">
              {{ item.productName }}
            </p>
            <div v-if="item.tradeType == '2'" class="lable">保证金</div>
            <div v-else class="lable2">全款</div>
            <!-- <p class="desc">{{ item.alt }}</p> -->
            <div class="desc">
              <Numeral
                :value="item.activePrice"
                format="0,0.00"
              >
                <template #prefix>
                  <strong>¥</strong>
                </template>
              </Numeral>
              /{{ item.unitName }}
            </div>
          </RouterLink>
        </li>
      </ul>
      <HomeSkeleton v-else />
    </Transition>
  </HomePanel>
</template>

<script>
import HomePanel from '@/views/home/components/HomePanel'
import { ref } from 'vue'
import HomeSkeleton from '@/views/home/components/HomeSkeleton'
export default {
  name: 'HomeHot',
  components: { HomeSkeleton, HomePanel },
  data () {
    return {
      good: {
        id: 1569014,
      },
      goods: {},
      item: {
        subCategoryId: '',
      },
      // homeHot: [1, 2, 3, 4],
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const postData = ref({
        subCategoryId: '',
        productName: '',
        sendFromCityCode: '',
        page: 1,
        rows: 4,
      })
      //  console.log('sortParams:', this.$route.params.id)
      // 获取商品列表
      this.$store
        .dispatch('ProductList', postData.value)
        .then((response) => {
          this.goods = response.result
        })
        .catch(() => {})
    },
  },
  setup () {},
}
</script>
<style scoped lang="less">
.goods-list {
  display: flex;
  justify-content: space-between;
  height: 426px;
  // margin-right: 50px;
  .lable {
    background: #fff4e3;
    font-size: 11px;
    color: #dc9100;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    border-radius: 4px;
    padding: 2px;
    width: 40px;
    height: 22px;
  }
  .lable2 {
    background: #ffeae9;
    font-size: 11px;
    color: #d12b23;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    border-radius: 4px;
    padding: 2px;
    width: 40px;
    height: 22px;
  }
  li {
    width: 306px;
    .hoverShadow();
    img {
      width: 306px;
      height: 306px;
    }
    p {
      font-size: 13px;
      font-weight: 600;
      padding-top: 12px;
      // text-align: center;
    }
    .desc {
			margin-top: 10px;
      color: #d12b23;
      font-size: 16px;
    }
  }
}
</style>
