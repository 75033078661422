<template>
  <div class="home-banner">
    <!-- <XtxCarousel v-if="banners"
                 :carousels="banners"
                 autoPlay /> -->
    <!-- <Carousel autoplay
              v-for="item in banners"
              :key="item"
              style="height: 100%"
              v-model="value"
              loop>
      <CarouselItem>
        <div class="demo-carousel">{{ item }}</div>
      </CarouselItem>
    </Carousel> -->
    <Carousel v-model="value" autoplay style="height: 100%" loop>
      <CarouselItem v-for="item in banners" :key="item">
        <img :src="item" />
        <!-- <div class="demo-carousel">{{ item }}</div> -->
      </CarouselItem>
    </Carousel>
  </div>
</template>
<script>
// import useBanners from "@/hooks/useBanners";
import { toRaw } from 'vue'
export default {
  name: 'HomeBanner',
  data () {
    return {
      banners: [],
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.$store
        .dispatch('AdpictureList')
        .then((response) => {
          const resion = response.result.map((item) => [item.picture])
          // console.log(resion
          this.banners = resion
          this.banners = toRaw(this.banners)
          // console.log("resion:", resion);
        })
        .catch(() => {})
    },
  },
  setup () {
    // const banners = useBanners();
    // console.log("banners", banners);
    // return {
    //   banners,
    // };
  },
}
</script>
<style scoped lang="less">
.home-banner {
  // width: 1240px;
  width: 670px;
  height: 455px;
  position: absolute;
  left: 260px;
  top: 0;
  z-index: 98;

  .xtx-carousel {
    // :deep(.carousel-btn.prev) {
    //   left: 270px;
    // }
    // :deep(.carousel-indicator) {
    //   padding-left: 250px;
    // }
  }
}

img {
  width: 100%;
  height: 455px;
  object-fit: contain;
  background: #fff;
}
</style>
